import { defineStore } from 'pinia';
import { Tokens } from '~/utils/constants';
import type { IUserModel, IUserSubscribers } from '~/types/apiService';
import type { TNullable } from '~/types/common';

export const useMainStore = defineStore('main', {
  state: () => ({
    error: '' as unknown,
    notificationUpdateVersion: 0,
    currentToken: Tokens.pearl,
    userData: null as TNullable<IUserModel>,
    userSubscribers: null as TNullable<IUserSubscribers>,
    weatherValue: 0
  }),
  getters: {
    getError: (state) => state.error
  },
  actions: {
    setError(error: unknown) {
      this.error = error;
    },
    updateVersion() {
      this.notificationUpdateVersion++;
    },
    setCurrentToken(token: Tokens) {
      this.currentToken = token;
    },
    setUserData(userData: IUserModel | null) {
      this.userData = userData;
    },
    setUserSubscribersData(userSubscribers: IUserSubscribers) {
      this.userSubscribers = userSubscribers;
    },
    setRandomWeatherValue() {
      const currentHourEpoch = new Date().getUTCHours();
      const hash = Math.sin(currentHourEpoch).toString();
      this.weatherValue = parseInt(hash.substring(3, 11), 16) % 12;
    }
  }
});
